import React from "react";
import "./clients-logo-three.scss";
import { StaticQuery, graphql } from 'gatsby';

class ClientsLogoThree extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
                query {
                      AtlassianHorizontalBlueLogo: file(relativePath: { eq: "Atlassian-horizontal-blue-rgb.svg" }) {
        publicURL
      },
                }
              `}
        render={data => (
          <img alt="" className="client-logo-three" src={data.AtlassianHorizontalBlueLogo.publicURL}/>
        )}
      />
    );
  }
}

export default ClientsLogoThree

