import React from "react";
import Layout from "../components/shared/layout/layout";
import Seo from "../components/seo";
import Intro from "../components/home/intro/intro";
import SalesforceDigital from "../components/home/salesforce-digital/salesforce-digital";
import AboutUs from "../components/home/about-us/about-us";
import Partners from "../components/home/partners/partners";
import DigitalProduct from '../components/home/digital-product/digital-product';
import OurClients from '../components/home/our-clients/our-clients';

const IndexPage = () => (
  <Layout>
    <Seo title="Overview" />
    <Intro/>
    <SalesforceDigital/>
    <AboutUs/>
    <Partners/>
    <DigitalProduct/>
    <OurClients/>
  </Layout>
)

export default IndexPage
