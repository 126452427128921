import React from "react";
import "./our-clients.scss";
import ClientsBackgroundImg from './clients-background-img/clients-background-img';
import ClientsLogoOne from './clients-logos/clients-logo-one/clients-logo-one';
import ClientsLogoTwo from './clients-logos/clients-logos-two/clients-logos-two';
import ClientsLogoThree from './clients-logos/clients-logo-three/clients-logo-three';
import ClientsLogoFive from './clients-logos/clients-logo-five/clients-logo-five';
import ClientsLogoSix from './clients-logos/clients-logo-six/clients-logo-six';
import ClientsLogoSeven from './clients-logos/clients-logo-seven/clients-logo-seven';
import ClientsLogoEight from './clients-logos/clients-logo-eight/clients-logo-eight';
import ClientsLogoNine from './clients-logos/clients-logo-nine/clients-logo-nine';
import ClientsLogoTen from './clients-logos/clients-logo-ten/clients-logo-ten';
import ClientsLogoEleven from './clients-logos/clients-logo-eleven/clients-logo-eleven';
import ClientsLogoTwelve from './clients-logos/clients-logo-twelve/clients-logo-twelve';
import ClientsLogoFourteen from './clients-logos/clients-logo-fourteen/clients-logo-fourteen';
import ClientsLogoThirteen from './clients-logos/clients-logo-thirteen/clients-logo-thirteen';
import ClientsLogoFifteen from './clients-logos/clients-logo-fifteen/clients-logo-fifteen';
import ClientsLogoFour from './clients-logos/clients-logo-four/clients-logo-four';
import Slider from 'react-slick';

class OurClients extends React.Component {
  render() {
    const settings = {
      autoplay: true,
      autoplaySpeed: 2000,
      centerMode: false,
      dots: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: false,
      responsive: [
        {
          breakpoint: 1170,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        }
      ],
    };

    return (
      <div className="our-client" id="clients">
        <ClientsBackgroundImg/>
        <div className="our-client-main"
             data-sal="fade"
             data-sal-easing="ease-in-back"
             data-sal-duration="1000"
        >
          <p>our</p>
          <p>Clients</p>
          <Slider {...settings} className="our-client-main-logos">
            <ClientsLogoOne/>
            <ClientsLogoTwo/>
            <ClientsLogoThree/>
            <ClientsLogoFour/>
            <ClientsLogoFive/>
            <ClientsLogoSix/>
            <ClientsLogoSeven/>
            <ClientsLogoEight/>
            <ClientsLogoNine/>
            <ClientsLogoTen/>
            <ClientsLogoEleven/>
            <ClientsLogoTwelve/>
            <ClientsLogoThirteen/>
            <ClientsLogoFourteen/>
            <ClientsLogoFifteen/>
          </Slider>
          {/*<div><span>See All Customer</span></div>*/}
        </div>
      </div>
    )
  }
}

export default OurClients
