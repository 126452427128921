import React from "react";
import "./digital-product.scss";
import { graphql, useStaticQuery, Link } from "gatsby";

const DigitalProduct = () => {
  const data = useStaticQuery(graphql`
    query {
      rightMain: file(relativePath: { eq: "home-digitalproduct-main.png" }) {
        publicURL
      },
      leftTop: file(relativePath: { eq: "home-digitalproduct-lefttop.svg" }) {
        publicURL
      },
      rightBottom: file(relativePath: { eq: "home-digitalproduct-rightbottom.svg" }) {
        publicURL
      },
      smallBlockInside: file(relativePath: { eq: "home-digitalproduct-smallblock-inside.svg" }) {
        publicURL
      }
    }
  `)
  return (
    <div className="digital-product">
      <div className="digital-product-left"
           data-sal="slide-right"
           data-sal-easing="ease"
           data-sal-duration="1000"
      >
        <div>
          <p>products</p>
          <p>Building Applications is Our Passion</p>
          <p>
            In addition to helping others with their most ambitious projects our team of creators and innovators never stops developing new ideas. Our exposure to modern business technologies is a constant inspiration behind our own unique product offerrings.
          </p>
          {/*<Link to="/product" className="yellow-btn">*/}
          {/*  Explore products*/}
          {/*</Link>*/}
        </div>
      </div>
      <div className="digital-product-right">
        <img alt="" src={data.rightMain.publicURL}/>
        <img alt="" src={data.leftTop.publicURL}/>
        <img alt="" src={data.rightBottom.publicURL}/>

        <div>
          <div>
            <p>new leads</p>
          </div>
          <div>
            <img alt="" src={data.smallBlockInside.publicURL}/>
          </div>
        </div>

      </div>
    </div>
  )
};

export default DigitalProduct
