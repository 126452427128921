import React from "react";
import "./about-us.scss";
import { graphql, useStaticQuery, Link } from "gatsby";

const AboutUs = () => {
  const data = useStaticQuery(graphql`
    query {
      aboutUsMainImg: file(relativePath: { eq: "about-us-main-img.png" }) {
        publicURL
      },
      aboutUsYellowGrid: file(relativePath: { eq: "about-us-yellow-grid.svg" }) {
        publicURL
      },
      aboutUsCurvyBg: file(relativePath: { eq: "about-us-curvy-bg.svg" }) {
        publicURL
      }
    }
  `);
  return (
    <div className="about-us container">
      <div className="about-us-left">
        <img alt="" className="about-us-main-img"
             src={data.aboutUsMainImg.publicURL}
             data-sal="slide-right"
             data-sal-easing="ease"
             data-sal-duration="1000"
        />
        <img alt="" className="about-us-yellow-grid" src={data.aboutUsYellowGrid.publicURL}/>
        <div className="about-us-circle"></div>
        <img alt="" className="about-us-curvy-bg" src={data.aboutUsCurvyBg.publicURL}/>
      </div>
      <div className="about-us-right"
           data-sal="slide-left"

           data-sal-easing="ease"
           data-sal-duration="1000"
      >
        <h5>about us</h5>
        <h2>Paladin Solutions</h2>
        <p>There has never been a more exciting time for technology. In this new era innovation is expected, execution is agile and growth is explosive. We thrive in todays demanding environment. Find out what makes us unique and how we can deliver instant value to your business.</p>
        <Link to="/about-us" className="yellow-btn">Learn more</Link>
      </div>
    </div>
  )
};

export default AboutUs
