import React from "react";
import "./clients-logo-eleven.scss";
import { StaticQuery, graphql } from 'gatsby';

class ClientsLogoEleven extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
                query {
                      fontevaLogo: file(relativePath: { eq: "fonteva_logo.png" }) {
        publicURL
      },
                }
              `}
        render={data => (
          <img alt="" className="client-logo-eleven" src={data.fontevaLogo.publicURL}/>
        )}
      />
    );
  }
}

export default ClientsLogoEleven

