import React from "react";
import "./partner-logo-one.scss";
import { StaticQuery, graphql } from 'gatsby';

class PartnerLogoOne extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
                query {
                      anablockLogo: file(relativePath: { eq: "anablock.svg" }) {
        publicURL
      },
                }
              `}
        render={data => (
          <img alt="" className="part-logo-one" src={data.anablockLogo.publicURL}/>
        )}
      />
    );
  }
}

export default PartnerLogoOne

