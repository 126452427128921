import React from "react";
import "./salesforce-digital.scss";
import { graphql, useStaticQuery, Link } from "gatsby";

const SalesforceDigital = () => {
  const data = useStaticQuery(graphql`
    query {
      salesforceDigitalBg: file(relativePath: { eq: "salesforce-digital-bg.svg" }) {
        publicURL
      },
      productDevelopment: file(relativePath: { eq: "product-development-2.svg" }) {
        publicURL
      },
      salesforceConsultingImg: file(relativePath: { eq: "salesforce-consulting-img-2.svg" }) {
        publicURL
      }
    }
  `)
  return (
    <div className="salesforce-digital" id="salesforce-digital">
      <img alt="" className="salesforce-digital-bg" src={data.salesforceDigitalBg.publicURL}/>
      <div className="salesforce-digital-container container">
        <div className="salesforce-digital-left"
             data-sal="zoom-out"
             data-sal-delay="300"
             data-sal-easing="ease"
             data-sal-duration="1000"
        >
          <img alt="" src={data.productDevelopment.publicURL}/>
          <h3>Salesforce and Digital Product Development</h3>
          <p>We build the best in class SAAS applications and business engines. Our expert team brings to life applications and products of any complexity. We love a good challenge and the word "impossible" is simply not in our dictionary.</p>
          <Link to="/services#salesforce-product" className="transparent-btn">Learn more</Link>
        </div>
        <div className="salesforce-digital-right"
             data-sal="zoom-out"
             data-sal-delay="600"
             data-sal-easing="ease"
             data-sal-duration="1000"
        >
          <img alt="" src={data.salesforceConsultingImg.publicURL}/>
          <h3>Salesforce Consulting</h3>
          <p>We've been building on force.com since its infancy. As the platform evolved throughout the years so did our expertise. Today salesforce has become the standard for business applications and sky is no longer the limit. Let us help you power your business with the all the greatest force.com has to offer.</p>
          <Link to="/services#salesforce-consulting-second" className="transparent-btn">Learn more</Link>
        </div>
      </div>
    </div>
  )
};

export default SalesforceDigital
