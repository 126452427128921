import React from "react";
import "./clients-logo-twelve.scss";
import { StaticQuery, graphql } from 'gatsby';

class ClientsLogoTwelve extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
                query {
                      rokuLogo: file(relativePath: { eq: "roku-logo.svg" }) {
        publicURL
      },
                }
              `}
        render={data => (
          <img alt="" className="client-logo-twelve" src={data.rokuLogo.publicURL}/>
        )}
      />
    );
  }
}

export default ClientsLogoTwelve

