import React from "react";
import "./partners-bg-two.scss";
import { StaticQuery, graphql } from 'gatsby';

class PartnersBgTwo extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
                query {
                      partnersBgWhite: file(relativePath: { eq: "partners-bg-white.svg" }) {
        publicURL
      },
                }
              `}
        render={data => (
          <img alt="" className="partners-bg-white" src={data.partnersBgWhite.publicURL}/>
        )}
      />
    );
  }
}

export default PartnersBgTwo

