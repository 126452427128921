import React from "react";
import "./clients-logo-fourteen.scss";
import { StaticQuery, graphql } from 'gatsby';

class ClientsLogoFourteen extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
                query {
                      openSocietyFoundationsLogo: file(relativePath: { eq: "open-society-foundations.svg" }) {
        publicURL
      },
                }
              `}
        render={data => (
          <img alt="" className="client-logo-fourteen" src={data.openSocietyFoundationsLogo.publicURL}/>
        )}
      />
    );
  }
}

export default ClientsLogoFourteen

