import React from "react";
import "./clients-logo-five.scss";
import { StaticQuery, graphql } from 'gatsby';

class ClientsLogoFive extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
                query {
                      drumlLogo: file(relativePath: { eq: "druml.png" }) {
        publicURL
      },
                }
              `}
        render={data => (
          <img alt="" className="client-logo-five" src={data.drumlLogo.publicURL}/>
        )}
      />
    );
  }
}

export default ClientsLogoFive

