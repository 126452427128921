import React from "react";
import "./partner-logo-two.scss";
import { StaticQuery, graphql } from 'gatsby';

class PartnerLogoTwo extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
                query {
                      cloudX: file(relativePath: { eq: "CloudX.png" }) {
        publicURL
      },
                }
              `}
        render={data => (
          <img alt="" src={data.cloudX.publicURL}/>
        )}
      />
    );
  }
}

export default PartnerLogoTwo

