import React from "react";
import "./partner-logo-seven.scss";
import { StaticQuery, graphql } from 'gatsby';

class PartnerLogoSeven extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
                query {
                      pixelTagLogo: file(relativePath: { eq: "pixel-tag.png" }) {
        publicURL
      },
                }
              `}
        render={data => (
          <img alt="" className="part-seven-logo" src={data.pixelTagLogo.publicURL}/>
        )}
      />
    );
  }
}

export default PartnerLogoSeven

