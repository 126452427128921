import React from "react";
import "./clients-logo-nine.scss";
import { StaticQuery, graphql } from 'gatsby';

class ClientsLogoNine extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
                query {
                      snowflakeLogo: file(relativePath: { eq: "snowflake-logo-blue.png" }) {
        publicURL
      },
                }
              `}
        render={data => (
          <img alt="" className="client-logo-nine" src={data.snowflakeLogo.publicURL}/>
        )}
      />
    );
  }
}

export default ClientsLogoNine

