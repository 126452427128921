import React from "react";
import "./clients-logo-four.scss";
import { StaticQuery, graphql } from 'gatsby';

class ClientsLogoFour extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
                query {
                      directsuretyLogo: file(relativePath: { eq: "directsurety.png" }) {
        publicURL
      },
                }
              `}
        render={data => (
          <img alt="" className="client-logo-four" src={data.directsuretyLogo.publicURL}/>
        )}
      />
    );
  }
}

export default ClientsLogoFour

