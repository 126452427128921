import React from "react";
import "./clients-background-img.scss";
import { StaticQuery, graphql } from 'gatsby';

class ClientsBackgroundImg extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
                query {
                      backgroundImg: file(relativePath: { eq: "home-ourclients-background.png" }) {
        publicURL
      },
                }
              `}
        render={data => (
          <img alt="" src={data.backgroundImg.publicURL}/>
        )}
      />
    );
  }
}

export default ClientsBackgroundImg

