import React from "react";
import "./partners.scss";
import PartnersBgOne from './partners-bg-one/partners-bg-one';
import PartnersBgTwo from './partners-bg-two/partners-bg-two';
import PartnersBgThree from './partners-bg-three/partners-bg-three';
import PartnerLogoOne from './partners-logos/partner-logo-one/partner-logo-one';
import PartnerLogoThree from './partners-logos/partner-logo-three/partner-logo-three';
import PartnerLogoSix from './partners-logos/partner-logo-six/partner-logo-six';
import PartnerLogoSeven from './partners-logos/partner-logo-seven/partner-logo-seven';
import PartnerLogoTwo from './partners-logos/partner-logo-two/partner-logo-two';
import PartnerLogoFive from './partners-logos/partner-logo-five/partner-logo-five';
import PartnerLogoEight from './partners-logos/partner-logo-eight/partner-logo-eight';
import Slider from 'react-slick';

class Partners extends React.Component {
  render() {
    const settings = {
      autoplay: true,
      autoplaySpeed: 2000,
      centerMode: false,
      dots: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      arrows: false,
      responsive: [
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        }
      ],
    };

    return (
      <div className="partners">
        <PartnersBgOne/>
        <PartnersBgTwo/>
        <div className="partners-logos-container"></div>
        <PartnersBgThree/>
        <div className="partners-content"
             data-sal="fade"
             data-sal-easing="ease-in-back"
             data-sal-duration="1000"
        >
          <h5>our friends and...</h5>
          <h2>Partners</h2>
            <Slider {...settings} className="partners-content-logos">
              <PartnerLogoOne/>
              <PartnerLogoTwo/>
              <PartnerLogoThree/>
              <PartnerLogoFive/>
              <PartnerLogoSix/>
              <PartnerLogoSeven/>
              <PartnerLogoEight/>
            </Slider>
          {/*<button className="transparent-btn">Learn more</button>*/}
        </div>
      </div>
    )
  }
}

export default Partners
