import React from "react";
import "./partner-logo-five.scss";
import { StaticQuery, graphql } from 'gatsby';

class PartnerLogoFive extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
                query {
                      masonFrankLogo: file(relativePath: { eq: "mason-frank.png" }) {
        publicURL
      },
                }
              `}
        render={data => (
          <img alt="" className="part-five-logo" src={data.masonFrankLogo.publicURL}/>
        )}
      />
    );
  }
}

export default PartnerLogoFive

