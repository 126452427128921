import React from "react";
import "./partner-logo-six.scss";
import { StaticQuery, graphql } from 'gatsby';

class PartnerLogoSix extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
                query {
                      salesforceLogo: file(relativePath: { eq: "logo-salesforce.svg" }) {
        publicURL
      },
                }
              `}
        render={data => (
          <img alt="" src={data.salesforceLogo.publicURL}/>
        )}
      />
    );
  }
}

export default PartnerLogoSix

