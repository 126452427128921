import React from "react";
import "./partners-bg-one.scss";
import { StaticQuery, graphql } from 'gatsby';

class PartnersBgOne extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
                query {
                      partnersBgGrey: file(relativePath: { eq: "partners-bg-grey.png" }) {
        publicURL
      },
                }
              `}
        render={data => (
          <img alt="" className="partners-bg-grey" src={data.partnersBgGrey.publicURL}/>
        )}
      />
    );
  }
}

export default PartnersBgOne

